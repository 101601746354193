.spin {
    position: absolute;
    animation: spin 1.5s linear infinite;
    transform-origin: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }